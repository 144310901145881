import React from 'react'
import WatchTogetherPage from '../components/pages/WatchTogether'
import PrivateLayout from '../layouts/private'

const WatchPage = (props) => {
  return <PrivateLayout><WatchTogetherPage /></PrivateLayout>
}

WatchPage.propTypes = {

}

WatchPage.defaultProps = {

}

export default WatchPage
