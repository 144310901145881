import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Div100vh from 'react-div-100vh'
import { colors } from '../../../theme'
import { Link } from 'gatsby'

const Wrapper = styled(Div100vh)`
  width: 100vw;
  > iframe {
    height: calc(100% - 50px);
  }
`

const Header = styled.div`
  position: relative;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  a {
    opacity: 0.7;
    filter: grayscale(0.6);
    font-size: 31px;
  }
  background-image: linear-gradient(to left, ${colors.primary}, ${colors.secondary});
  box-shadow: inset 0 -2px 2px rgba(255,255,255,0.4);
  > :nth-child(2) {
    position: absolute;
    right: 20px;
    color: white;
    font-family: 'Dancing Script', cursive;
    justify-self: flex-end;
  }
`

const WatchTogetherPage = (props) => {
  return <Wrapper>
    <Header><Link to='/'>👅🦷❤️</Link><div>J'nous ai même ramené ça!</div></Header>
    <iframe src="https://www.watch2gether.com/rooms/xgxfye56gw6klswbzx?lang=fr&app=1" width="100%" height="100%" frameborder="0" allowFullScreen />
  </Wrapper>
}

WatchTogetherPage.propTypes = {

}

WatchTogetherPage.defaultProps = {

}

export default WatchTogetherPage
